<template>
  <div class="materialsTransform nav_tab_wrap">
    <el-tabs class="nav_wrap" type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生产计划" name="finishedProduction">
        <finishedProduction :isMaterialsTransform="isMaterialsTransform" v-if="isFP"></finishedProduction>
      </el-tab-pane>
      <el-tab-pane label="生产执行" name="productionExecution">
        <productionExecution :isMaterialsTransform="isMaterialsTransform" v-if="isPE"></productionExecution>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import finishedProduction from '../finishedProduction/finishedOrder/Index';
import productionExecution from '../toolProduction/productionExecution/Index';
export default {
  name: 'materialsTransform',
  data() {
    return {
      activeName: 'finishedProduction',
      isFP: true,
      isPE: false,
      isMaterialsTransform: true
    };
  },
  components: {
    finishedProduction,
    productionExecution
  },
  created() {},
  mounted() {},
  methods: {
    handleClick(tab) {
      switch (tab.name) {
        case 'finishedProduction':
          this.isFP = true;
          this.isPE = false;
          break;
        case 'productionExecution':
          this.isFP = false;
          this.isPE = true;
          break;
        default:
          break;
      }
    }
  }
};
</script>